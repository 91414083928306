import { FELyricPart } from "@/models/frontendOnly/FELyricPart";
import { DynamicTranslationService } from "@/services/DynamicTranslationService";
import { cloneDeep } from "lodash";

const preChorusSpellings = [
  "pre - chorus",
  "pre -chorus",
  "pre- chorus",
  "pre-chorus",
  "prechorus",
  "pre chorus",
  "prechours",
  "pre chours",
  "pre-chours",
  "pre - chours",
  "pre- chours",
  "pre -chours",
];

// TODO: Provide translations here based on returned language/phrase app values
const lyricPartTypes: {key: number, label: string}[] = [
  {
    key: 880,
    label: DynamicTranslationService.lyricTypeChorusText
  },{ 
    key: 881, 
    label: DynamicTranslationService.lyricTypeVerseText
  },{ 
    key: 882, 
    label: DynamicTranslationService.lyricTypeBridgeText
  },{ 
    key: 883, 
    label: DynamicTranslationService.lyricTypeEndingText
  },{ 
    key: 884, 
    label: DynamicTranslationService.lyricTypeIntroText
  },{ 
    key: 885, 
    label: DynamicTranslationService.lyricTypePreChorusText
  },{ 
    key: 886, 
    label: DynamicTranslationService.lyricTypeInterludeText
  },{ 
    key: 887, 
    label: DynamicTranslationService.lyricTypeDescantText
  },{ 
    key: 888, 
    label: DynamicTranslationService.lyricTypeSpokenWordsText
  },{ 
    key: 889, 
    label: DynamicTranslationService.lyricTypeVampText
  },{ 
    key: 890, 
    label: DynamicTranslationService.lyricTypeRapText
  },{ 
    key: 1491, 
    label: DynamicTranslationService.lyricTypeTagText
  },{ 
    key: 1492, 
    label: DynamicTranslationService.lyricTypeMidSectionText
  },{ 
    key: 1503, 
    label: DynamicTranslationService.lyricTypePostChorusText
  },{ 
    key: 1510, 
    label: DynamicTranslationService.lyricTypeRefrainText
  },{ 
    key: 1511, 
    label: DynamicTranslationService.lyricTypeOstinatoText
  }
];

/**
 * This will return the translated Partname based on the selected language in the app's footer
 * @param partTypeLid Part Type LID
 * @param defaultPartTypeName Fallback PartTypeName
 * @returns the found translated ID
 */
function getPartName(partTypeLid: number, defaultPartTypeName: string){ 
  return lyricPartTypes.find(l => l.key === partTypeLid)?.label || defaultPartTypeName;
}

function fixPreChorusLabel(line: string) { 
  if (preChorusSpellings.includes(line.toLowerCase())){
    return getPartName(885, DynamicTranslationService.lyricTypePreChorusText);
  }
  return line;
}

export const defaultLyricPart: FELyricPart = {
  LyricPartCounter: 0, 
  LyricPartId: '880', 
  LyricPartText: [], 
  LyricPartTextAll: '', 
  LyricPartType: getPartName(880, ''),
  LyricPartTypeLid: '880',
  validationErrors: [], 
}

export const nullLyricPartId = '00000000-0000-0000-0000-000000000000';

export function importLyrics(existingParts: FELyricPart[], text: string): FELyricPart[] {
  
  const lyricParts: FELyricPart[] = existingParts;
  let currentPart = cloneDeep(defaultLyricPart);

  if (lyricParts.length === 1 && !lyricParts[0].LyricPartTextAll.trim()){
    lyricParts.splice(0,1);
  }

  let lastCorrectedLine = '';
  text.split('\n').forEach(line => {
    let correctedLine = line;

    correctedLine = correctedLine.trim();
    if (!correctedLine){ 
      lastCorrectedLine = correctedLine;
      return;
    }

    correctedLine = fixPreChorusLabel(correctedLine);
    const headerTextTest = correctedLine.replace(/^\(|\s/g, '').toLowerCase();
    const lyricPartType = lyricPartTypes.find(t => headerTextTest.startsWith(t.label.replace(/\s/g, '').toLowerCase()));

    if (lyricPartType && !lastCorrectedLine){ 
      if (currentPart.LyricPartTextAll.trim()){
        lyricParts.push(currentPart);
      }
      currentPart = { 
        LyricPartCounter: 1, 
        LyricPartId: nullLyricPartId, 
        LyricPartText: [], 
        LyricPartTextAll: '', 
        LyricPartType: getPartName(lyricPartType.key, ''),
        LyricPartTypeLid: lyricPartType.key.toString(),
        validationErrors: [],
      }
    }else{ 
      currentPart.LyricPartText.push(correctedLine);
      currentPart.LyricPartTextAll = currentPart.LyricPartText.join('\n');
    }
    lastCorrectedLine = correctedLine;
  });

  if (currentPart.LyricPartTextAll.trim()){
    lyricParts.push(currentPart);
  }

  return lyricParts;
}
